<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="produksi"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mt-4 px-8 pt-4 pb-10"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.order_start_date="{ item }">
          <div>
            <span> {{ item.order_start_date }} </span>
            <span> - </span>
            <span> {{ item.order_end_date }} </span>
            <span> {{ ' (' + item.order_total_days + ' Hari)' }} </span>
          </div>
        </template>

        <template v-slot:item.status_string="{ item }">
          <v-chip :color="'#' + item.status_color">
            <span style="color: #FFFFFF">
              {{ item.status_string }}
            </span>
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <div
            class="action-container"
            style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
            @click="toDetail(item)"
          >
            <v-icon
              class="action-icon"
              style="font-size: 16px"
            >
              fas fa-eye
            </v-icon>
          </div>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span>
          </div>
          <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card class="px-4 py-4">
        <v-row>
          <v-col
            class="d-flex"
            style="justify-content: space-between"
          >
            <div>Informasi Pesanan Offline</div>
            <div
              style="cursor: pointer"
              @click="dialog = false"
            >
              X
            </div>
          </v-col>
        </v-row>

        <template v-if="selectedPesananOffline !== null">
          <v-row>
            <v-col
              cols="3"
              sm="3"
            >
              Nama Mitra
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              {{ selectedPesananOffline.cust_name }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="3"
              sm="3"
            >
              Nama Perusahaan
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              {{ selectedPesananOffline.cust_company }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="3"
              sm="3"
            >
              Nama Penyewa
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              xxxxxxxxxxxxxx
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="3"
              sm="3"
            >
              Telepon
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              xxxxxxxxxxxxxx
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="3"
              sm="3"
            >
              Tanggal Pesanan
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              {{ selectedPesananOffline.created_time }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="3"
              sm="3"
            >
              Durasi
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              {{ selectedPesananOffline.duration }}
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dialog: false,

      // Produksi:
      search: '',
      headers: [
        { text: 'Kode Produksi', value: 'code', sortable: false },
        { text: 'Nama Media', value: 'ass_name' },
        { text: 'Lokasi', value: 'ass_add_kot' },
        { text: 'Nama Mitra', value: 'prt_company_name_legal' },
        { text: 'Nama Penyewa', value: 'cust_name' },
        { text: 'Tanggal Pemesanan', value: 'order_date' },
        { text: 'Durasi', value: 'order_start_date' },
        { text: 'Status', value: 'status_string' },
        { text: '', value: 'actions', sortable: false },
      ],
      produksi: [],

      isAllowDetail: false,
      isAllowVerification: false,

      selectedPesananOffline: null,

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      // this.getPerusahaan(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/production', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.produksi = res.data.data.list

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        this.$router.push({ name: 'ProduksiDetail', params: { id: item.hash } })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
